<template>
    <div class="card p-2" style="min-height: calc(100vh - 185px);">
      <div class="d-flex flex-wrap justify-content-between align-item-center">
        <h3>Chart of Accounts</h3>
        <div class="gx-1">
          <button
              @click="onClickListView"
              style="min-width: 64px;"
              class="btn btn-outline-secondary waves-effect waves-float waves-light me-1"
          > <i class="fa fa-list"></i> List View
          </button>
          <button
              style="min-width: 64px;"
              @click="openAddNewAccountModal"
              class="btn btn-primary waves-effect waves-float waves-light me-1"
          >Add New Group
          </button>
        </div>
      </div>

        <div class="mt-2"></div>
      <div class="row">
        <div class="col-12 col-lg-7">
          <Tab @onClickTab="onClickTab" :tabs="homeContent.account_type" />
        </div>
        <div class="col-12 col-lg-5">
          <div class="row">
            <div class="col-12 col-md-9">
              <AsyncSelect
                  placeholder="Select Head"
                  :key="accountHeadKey"
                  v-model="headId"
                  :api-service="fetchAccountHeadList"
                  :additional-query="{ account_type: selectedAccountType,status:'active' }"
              />
            </div>
            <div  class="col-md-3 d-flex align-items-end mt-1 mt-md-0 action-btn-setter">
              <button
                  style="min-width: 64px;"
                  @click="onClickGo"
                  class="btn btn-primary btn-block waves-effect waves-float waves-light"
              > Go
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr>
        <TreeBrowser
            :style="{opacity: chartLoading ? .5 : 1}"
            v-for="(item, i) in chartAccounts"
            :key="i"
            :node="item"
        />
        <p v-if="!chartAccounts.length" class="text-center mt-5">No data</p>
        <ChartCreateModal
            :isGroup="isGroup"
            :parentGroup="parentGroup"
            :accountType="homeContent.account_type"
            :accountSlug="homeContent.account_slug"
            :eventName="eventName"
            :title="title"
            :loading="chartCreateLoading"
            :formData="createData"
            ref="createChartAccount"
            @onSubmit="createChartAccount"
            @onHeadAdd="createHead"
            :tdsRateList="tdsRateList"
            :vdsRateList="vdsRateList"
        />
        <ChartEditModal
            :isGroup="isGroup"
            :eventName="editEventName"
            :title="editTitle"
            :loading="chartCreateLoading"
            :accountSlug="homeContent.account_slug"
            :formData="editData"
            :groups="groupsWithoutEditItem"
            ref="editChartAccount"
            @onUpdateHead="onUpdateHead"
            @onUpdateGroup="onUpdateGroup"
            :tdsRateList="tdsRateList"
            :vdsRateList="vdsRateList"
            :statusList="statusList"
        />
    </div>
</template>

<script>
import handleChartAccount from '@/services/modules/chartAccount'
import handleTdsVds from '@/services/modules/procurement/tdsVds'
import { inject } from 'vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import {mapMutations} from 'vuex'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";

import TreeBrowser from '@/components/atom/TreeBrowser'
import Tab from '@/components/atom/Tab'
import TitleButton from '@/components/atom/TitleButton'
import ChartCreateModal from '@/components/molecule/company/chart-of-account/CreateModal'
import ChartEditModal from '@/components/molecule/company/chart-of-account/EditModal'
import handleReport from "@/services/modules/accountingReport";

export default {
    name: 'ChartAccountList',

    components: {
        AsyncSelect,
        TitleButton,
        TreeBrowser,
        Tab,
        ChartCreateModal,
        ChartEditModal
    },

    data: () => ({
        selectedAccountType: null,
        accountHeadKey: 0,
        isGroup: false,
        parentGroup: '',
        homeContent: {},
        accountHeads: [],
        accountType:null,
        accountTypes:[],
        accountTypeSelector:null,
        headId: null,
        title: 'Add new account',
        editTitle: 'Add new account',
        eventName: '',
        editEventName: 'onSubmit',
        groups: [],
        groupsWithoutEditItem: [],
        currentEditItemId: null,
        tdsRateList: [],
        vdsRateList: [],
        statusList: [],
        createData: {
            name: '',
            account_type: '',
            status: 'active',
            account_head_id: null,
            tds_rate_id: null,
            vds_rate_id: null,
            account_slug: null,
            company_id: ''
        },
        editData: {
            name: '',
            account_type: '',
            status: 'active',
            account_head_id: null,
            tds_rate_id: null,
            vds_rate_id: null,
            account_slug: null,
            company_id: '',
            _method: 'PATCH',
        }
    }),

    created() {
         this.selectedAccountType = this.$route.params.accountType;
      },

    watch: {
      selectedAccountType(newVal) {
        this.headId = null;
        this.selectedAccountType = newVal
        this.accountHeadKey++;
      }
    },

    methods: {
        sortChart(data) {
            data.map(i => {
                i.child.sort((a, b) => {
                    if(a.type == 'group' && b.type == 'endpoint') return 1
                    if(a.type == 'endpoint' && b.type == 'group') return -1
                    if(a.type == b.type) return 0
                })
                this.sortChart(i.child)
            })
        },
        ...mapMutations({
            setChartAccount: 'chartAccount/setChartAccount'
        }),

        onClickAddNewButton() {
        },

        openAddNewAccountModal () {
            this.isGroup = false
            this.eventName = 'onSubmit'
            this.title = 'Add new group'
            this.createData.account_type = this.$route.params.accountType
            this.createData.company_id = this.$route.params.companyId
            this.createData.account_head_id = null
            this.$refs.createChartAccount.toggleModal();
        },

        onClickListView () {
          this.$router.push({name: 'chart-accounts-list',
            params: {
              companyId: this.$route.params.companyId,
              moduleId: this.$route.params.moduleId,
              menuId: this.$route.params.menuId,
              pageId: this.$route.params.pageId,
            }
          });
        },

        onClickTab(tabName) {
            this.selectedAccountType = tabName;
            this.headId = null;
            this.getChartAccount()
        },

        setGroupArr(data) {
            data.map(item => {
                if(item.type == 'group') {
                    this.groups.push({id: item.id, name: item.name})
                }
                this.setGroupArr(item.child)
            })
        },

        onClickGo() {
          this.$router.push({name: this.routeName,
            params: {
              companyId: this.$route.params.companyId,
              moduleId: this.$route.params.moduleId,
              menuId: this.$route.params.menuId,
              pageId: this.$route.params.pageId,
              accountType: this.$route.params.accountType
            }
          });
          this.getChartAccount();
        },

        async getChartAccount() {
            try {
                 let query = this.getQuery()
                this.chartLoading = true
                let res = await this.fetchChartAccount(query)
                if(!res.status) {
                    this.showError(res.message)
                    this.setChartAccount({})
                }
                if(res.status) {
                    this.setChartAccount(res.data)
                    this.sortChart(this.chartAccounts)
                    this.groups = []

                    this.setGroupArr(res.data)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartLoading = false
            }
        },

        async getAccountHeads() {
          try {
            let res = await this.fetchAccountHeads(this.$route.params.companyId);
            if (!res.status) {
              this.showError(res.message);
            }
            if (res.status) {
              this.accountHeads = res.data;
            }
          } catch (err) {

          } finally {
          }
        },

        async createChartAccount() {
            try {
                this.chartCreateLoading = true
                let res = await this.createNewAccount(this.createData)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.getChartAccount()
                    this.createData.name = '',
                    this.createData.status = 'active'
                    this.createData.account_head_id = null
                    this.$refs.createChartAccount.toggleModal();
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartCreateLoading = false
            }
        },

        async onUpdateGroup() {
            try {
                this.chartCreateLoading = true
                let res = await this.updateAccountGroup({
                    data: this.editData,
                    id: this.currentEditItemId
                })
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.$refs.editChartAccount.toggleModal();
                    this.getChartAccount()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartCreateLoading = false
            }
        },

        async createHead() {
            try {
                this.chartCreateLoading = true
                let res = await this.createNewAccountHead(this.createData)
                if(!res.status) { 
                    this.showError(res.message)
                }
                if(res.status) {
                    this.getChartAccount()
                    this.createData.name = '',
                    this.createData.status = 'active'
                    this.createData.account_head_id = null
                    this.$refs.createChartAccount.toggleModal();
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartCreateLoading = false
            }
        },

        async onUpdateHead() {
            try {
                this.chartCreateLoading = true
                let res = await this.updateAccountHead({
                    data: this.editData,
                    id: this.currentEditItemId
                })
                if(!res.status) { 
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.$refs.editChartAccount.toggleModal();
                    this.getChartAccount()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartCreateLoading = false
            }
        },

        async getHomeContent() {
            try {
                this.chartLoading = true
                let res = await this.fetchHome()
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.homeContent = res.data
                }
                if (res.status && res.data.status){
                  const allowedStatuses = ['active', 'inactive'];

                  this.statusList = Object.keys(res.data.status)
                      .filter(key => allowedStatuses.includes(key))
                      .map(key => ({
                        value: key,
                        name: res.data.status[key]
                      }));
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartLoading = false
            }
        },

        getQuery() {
            let query = '?company_id=' + this.$route.params.companyId;
            query += '&account_type=' + this.$route.params.accountType
            if (this.headId) query += '&account_head=' + this.headId.id
            return query;
        },

        async getTdsRates() {
            const query =  this.getQuery();

            await this.fetchTdsRateList(query).then(res => {
                if(res.status){
                    this.tdsRateList = res.data;
                }
            }).catch(err => {
                console.log(err)
            })
        },

        async getVdsRates() {
            const query =  this.getQuery();

            await this.fetchVdsRateList(query).then(res => {
                if(res.status){
                    this.vdsRateList = res.data;
                }
            }).catch(err => {
                console.log(err)
            })
        }
    },

    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const store = useStore()  

        const {
            fetchChartAccount,
            fetchHome,
            createNewAccount,
            createNewAccountHead,
            chartCreateLoading,
            updateAccountGroup,
            updateAccountHead,
            chartLoading
        } = handleChartAccount();

        const {
          fetchAccountHeads,
          fetchAccountHeadList
        } = handleReport();

        const {
            fetchTdsRateList,
            fetchVdsRateList
        } = handleTdsVds();

        return {
            chartAccounts: computed(() => store.state.chartAccount.chartAccounts),
            fetchChartAccount,
            createNewAccount,
            createNewAccountHead,
            chartCreateLoading,
            updateAccountGroup,
            updateAccountHead,
            chartLoading,
            fetchTdsRateList,
            fetchVdsRateList,
            fetchAccountHeads,
            fetchAccountHeadList,
            fetchHome,
            showError,
            showSuccess
        }
    },

    mounted () {
        const createToggleModal = this.$refs.createChartAccount
        const editToggleModal = this.$refs.editChartAccount

        this.getChartAccount()
        this.getHomeContent() //status, voucher, account_type
        this.getTdsRates();
        this.getVdsRates();
        this.getAccountHeads()

        this.emitter.on('onClickAddGroup', (evt) => {
            this.isGroup = true
            this.title = 'Add new sub group'
            this.eventName = 'onSubmit'
            this.createData.account_type = this.$route.params.accountType
            this.createData.company_id = this.$route.params.companyId
            this.createData.account_head_id = evt.id
            this.parentGroup = evt.name
            createToggleModal.toggleModal();
        })

        this.emitter.on('onClickAddHead', (evt) => {
            this.isGroup = false;
            this.eventName = 'onHeadAdd'
            this.title = 'Add new account head'
            this.createData.account_type = this.$route.params.accountType
            this.createData.company_id = this.$route.params.companyId
            this.createData.account_head_id = evt.id
            this.parentGroup = evt.name
            createToggleModal.toggleModal();
        })

        this.emitter.on('onChartUpdate', (evt) => {
            this.editTitle = evt.title
            this.currentEditItemId = evt.data.id
            if(evt.eventName == 'onUpdateHead') {
                this.isGroup = false;
                this.groupsWithoutEditItem = this.groups
            }
            if(evt.eventName == 'onUpdateGroup') {
                this.isGroup = true
                this.groupsWithoutEditItem = this.groups.filter(group => group.id !== evt.data.id)
                this.groupsWithoutEditItem.unshift({id: null, name: 'Set as parent'})
            }
            this.editEventName = evt.eventName
            this.editData.account_head_id = evt.data.account_head_id
            this.editData.tds_rate_id = evt.data.tds_rate_id
            this.editData.vds_rate_id = evt.data.vds_rate_id
            this.editData.name = evt.data.name
            this.editData.account_slug = evt.data.account_slug
            this.editData.status = evt.data.status
            this.editData.company_id = this.$route.params.companyId
            this.editData.account_type = this.$route.params.accountType
            editToggleModal.toggleModal();
        })
    }
}
</script>
